import React, { useState, useEffect } from 'react';
import 'react-18-image-lightbox/style.css';
import Lightbox from 'react-18-image-lightbox';
import axios from 'axios'; // For making the API request to the Node.js backend

function App() {
  const images = [
    '/images/1.jpg',
    '/images/2.jpg',
    '/images/3.jpg',
    '/images/4.jpg',
    '/images/5.jpg',
    '/images/6.jpg',
    '/images/7.jpg',
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [nodeMessage, setNodeMessage] = useState(''); // State to store the message from Node.js
  const [connectionError, setConnectionError] = useState(false); // State to track connection errors

  // Use useEffect to fetch the message from Node.js on component mount
  useEffect(() => {
    axios
      .get('https://node.oksami.ee/api/message') // Change port to 5001
      .then((response) => {
        setNodeMessage(response.data.message);
      })
      .catch((error) => {
        setConnectionError(true);
        console.error('Error connecting to Node.js backend:', error);
      });
  }, []);

  return (
    <div style={{ textAlign: 'center', backgroundColor: '#f0f8ff', padding: '20px' }}>
      <h1>Oksami</h1>

      <h2>Veebileht on väljatöötamisel.</h2>
      <h2>The website is under development.</h2>
      <h2>Веб Сайт находиться в стадии разработки.</h2>

      {/* Display images in a grid */}
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>
        {images.map((img, index) => (
          <img
            key={index}
            src={img}
            alt={`Image ${index + 1}`}
            style={{ width: '150px', height: 'auto', cursor: 'pointer' }}
            onClick={() => {
              setIsOpen(true);
              setPhotoIndex(index);
            }}
          />
        ))}
      </div>

      {/* Lightbox for full-screen view */}
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}

      {/* Test for Node.js connection */}
      <div style={{ marginTop: '20px' }}>
        <h3>Node.js Backend Connection Test</h3>
        {connectionError ? (
          <p style={{ color: 'red' }}>There is no connection to the backend.</p>
        ) : (
          <p>Message from Node.js: {nodeMessage || 'Loading message...'}</p>
        )}
      </div>
    </div>
  );
}

export default App;
